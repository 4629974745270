body {
  margin: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 40px !important;
}

.section {
  scroll-margin-top: 65px;
}

#renderCanvas {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  touch-action: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.clickable {
  cursor: pointer;
}

.modal-body {
  overflow: hidden;
}
/* 
.active {
  color: "white" !important;
} */

.background-gray {
  background-color: #eee;
}

.background-white {
  background-color: #fff;
}
/* Remove active nav link and use scrollspy instead */
.nav-link.active {
  color: rgba(255, 255, 255, 0.55) !important;
}

.nav-link.active2 {
  color: white !important;
}

.company-logo {
  width: 300px;
  height: 40px;
}
@media (max-width: 400px) {
  .company-logo {
    width: 250px;
    height: 40px;
  }
}
